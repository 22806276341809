// src/components/Header/Header.js
import React from 'react';
import headerImage from '../assets/header.png';
import logoImage from '../assets/logo.png';
import './Header.css';

const Header = () => {
  return (
    <header className="header" style={{ backgroundImage: `url(${headerImage})` }}>
      <img src={logoImage} alt="Logo" className="logo" />

      <div className="text-container">
        <h1 className="title">
          Tired of your favorite shows being removed from your streaming platforms?
        </h1>
        <p className="subtitle">
          You can often access your favorite shows on your existing streaming services, via different countries. Find out where they’re available and how to continue to watch them.
        </p>

        <a href="#search-bar" className="button-link">
          <button className="button">Find My Show</button>
        </a>
      </div>
    </header>
  );
};

export default Header;
