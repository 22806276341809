// src/components/SearchSection/SearchSection.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import vpnImage from '../assets/computer-vpn.png';
import ResultsTable from './ResultsTable';
import LoadingOverlay from './LoadingOverlay';
import './SearchSection.css';

import { countries as CountryList } from '../assets/countries'; // Import the countries array from the countries.js file

require('dotenv').config()

const API_HOST = process.env.REACT_APP_API_HOST; // API host URL

const SearchSection = () => {
    const [countries, setCountries] = useState([]);
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [results, setResults] = useState(null); // State for storing API results
    const [showDialog, setShowDialog] = useState(false); // State for dialog box visibility
    const [suggestionSelected, setSuggestionSelected] = useState('');
	const [loading, setLoading] = useState(false); // State for loading overlay

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                // const response = await axios.get(`${API_HOST}/api/countries`);
                setCountries(CountryList);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchCountries();
    }, []);

    useEffect(() => {
        const fetchSuggestions = async () => {
            if (query.length < 3 || suggestionSelected === query) {
                setSuggestions([]);
                return;
            }
            try {
                const response = await axios.get(`https://api.tvmaze.com/search/shows?q=${query}`);
                const shows = [...new Set(response.data.map((item) => item.show.name))];
                setSuggestions(shows);
            } catch (error) {
                console.error('Error fetching show suggestions:', error);
            } 
        };
        fetchSuggestions();
    }, [query, suggestionSelected]);

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value);
    };

    const handleSuggestionClick = (suggestion) => {
        setQuery(suggestion);
        setSuggestionSelected(suggestion);
        setSuggestions([]);
    };

    const handleSearch = async () => {
        if (!query || !selectedCountry) {
            alert('Please enter a show name and select a country.');
            return;
        }

		setLoading(true);
        try {
            const response = await axios.post(`${API_HOST}/api/search`, {
                query,
                country: selectedCountry,
            });
            setResults(response.data); // Display data in ResultsTable
        } catch (error) {
            console.error('Error fetching streaming data:', error);
        } finally {
			setLoading(false);
		}
    };

    return (
        <section className="section-style">
			<LoadingOverlay isLoading={loading}/>
            <div className="content-row">
                <div className="text-column">
                    <p className="text">
                        All you have to do is search for your favorite show and see which streaming services it is
                        available and in which countries.
                        <br />
                        <br />
                        Then, simply connect to your VPN for that country, login to Netflix or other streaming service,
                        and get binge-watching all over again! Don’t have a VPN?
                        <a className="link" href="#getvpn" onClick={() => setShowDialog(true)}>
                            Click HERE!
                        </a>
                    </p>
                </div>
                <div className="image-column">
                    <img src={vpnImage} alt="VPN Computer" className="image" />
                </div>
            </div>

            {showDialog && (
                <div className="dialog-overlay">
                    <div className="dialog-box">
                        <p className="dialog-text">Get NordVPN today to continue watching your favorite shows.</p>
                        <a href="https://nordvpn.sjv.io/oqQj1o" className="dialog-button">
                            Watch Shows
                        </a>
                        <button className="dialog-close-button" onClick={() => setShowDialog(false)}>
                            Close
                        </button>
                    </div>
                </div>
            )}

            {/* Search Box Section */}
            <div id="search-bar" className="search-box-container">
                <input
                    type="text"
                    placeholder="Type the name of your show..."
                    value={query}
                    onChange={handleInputChange}
                    className="search-input"
                />
                {suggestions.length > 0 && (
                    <ul className="suggestion-list">
                        {suggestions.map((suggestion, index) => (
                            <li
                                key={index}
                                onMouseDown={() => handleSuggestionClick(suggestion)}
                                className="suggestion-item"
                            >
                                {suggestion}
                            </li>
                        ))}
                    </ul>
                )}
                <select onChange={handleCountryChange} className="dropdown">
                    <option>Select Country</option>
                    {countries.map((country, index) => (
                        <option key={index} value={country}>
                            {country}
                        </option>
                    ))}
                </select>
                <button onClick={handleSearch} className="search-button">
                    Find My Show
                </button>
            </div>

            {/* Conditionally render ResultsTable only if results are available */}
            {results && (
                <>
                    <ResultsTable data={results} />
                    <div className="button-container">
                        <button className="button" onClick={() => setShowDialog(true)}>
                            Get VPN to keep watching
                        </button>
                    </div>
                </>
            )}
        </section>
    );
};

export default SearchSection;
