// src/App.js
import React from 'react';
import { Header, SearchSection, Footer } from './components';
import './App.css';

const App = () => {
  return (
    <div className='appContainer'>
      <div className='mainContentStyle'>
        <Header />
        <SearchSection />
      </div>
      <Footer /> {/* Footer will be anchored at the bottom */}
    </div>
  );
};

export default App;