// src/components/ResultsTable.js
import React from 'react';
import './ResultsTable.css';

function ResultsTable({ data }) {
    // Check if data is an array; if not, render a message or handle appropriately
    if (!data || !Array.isArray(data) || data.length === 0) {
        console.log('ResultsTable data:', data); // Log data to help with debugging
        return <p>No data available or invalid data format</p>;
    }

    return (
        <div className="container-style">
            <table className="table-style">
                <thead>
                    <tr>
                        <th className="header-style">Service</th>
                        <th className="header-style">Country Available</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td className="cell">{item.service}</td>
                            <td className="cell">{item.country}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ResultsTable;
