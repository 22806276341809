import React, { useEffect, useState } from 'react';
import './LoadingOverlay.css';

const LoadingOverlay = ({ isLoading }) => {
    const [visible, setVisible] = useState(isLoading);

    useEffect(() => {
        if (!isLoading) {
            const timer = setTimeout(() => setVisible(false), 250); // Match the fade-out duration
            return () => clearTimeout(timer);
        } else {
            setVisible(true);
        }
    }, [isLoading]);

    return (
        visible && (
            <div className={`loading-overlay ${!isLoading ? 'fade-out' : ''}`}>
                <div className="loading-spinner"></div>
            </div>
        )
    );
};

export default LoadingOverlay;