// src/components/Footer/Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faTiktok, faInstagram } from '@fortawesome/free-brands-svg-icons';
import logoImage from '../assets/logo.png';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="left-section">
                <img src={logoImage} alt="Logo" className="logo" />
                <a href="https://itscheaper.in" className="text-link">
                    try our other site itscheaper.in
                </a>
            </div>
            <div className="right-section">
                <a href="https://x.com" target="_blank" rel="noopener noreferrer" className="icon">
                    <FontAwesomeIcon icon={faXTwitter} />
                </a>
                <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer" className="icon">
                    <FontAwesomeIcon icon={faTiktok} />
                </a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="icon">
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
